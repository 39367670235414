import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { BodyTextBlock } from '@/features/common/'
import { Quote } from '@/features/common/'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  body?: Queries.BodyTextBlockFragment | null
  heading?: string | ReactNode | null
  quote?: Queries.QuoteFragment | null
}

export const IntroSection = ({
  heading,
  body,
  quote,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      background: #fff;
      padding: var(--row-72) var(--margin);
      display: grid;
      grid-column-gap: var(--gtr-72);
      grid-row-gap: 2em;
      ${quote &&
      css`
        grid-template-columns: 2fr 1fr;
      `}
      ${heading &&
      css`
        grid-template-columns: 1fr 2fr;
      `}
      ${mq().m} {
        grid-template-columns: 1fr;
      }
    `,
    heading: css`
      color: ${colors.gray10};
      font-size: var(--fs-72);
      margin: 0;
    `,
    body: css`
      font-size: var(--fs-18);
      line-height: 2;
      max-width: 90ch;
      p {
        margin-top: 0.5em;
      }
      a {
        color: ${colors.red};
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
      > [data-button] {
        display: inline-block;
        margin: 0.75em 1.5em 1em 0;
        a + & {
          margin-right: 0;
          margin-top: 0;
        }
      }
    `,
    quote: css``,
    button: css`
      display: inline-block;
      margin: 0.75em 1.5em 1em 0;
      a + & {
        margin-right: 0;
        margin-top: 0;
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      {heading && <h2 css={styles.heading}>{heading}</h2>}
      <BodyTextBlock
        data={body}
        css={styles.body}
      />
      {quote && (
        <Quote
          data={quote}
          layout="INTRO"
          css={styles.quote}
        />
      )}
    </section>
  )
}

export const IntroSectionFragment = graphql`
  fragment IntroSection on DatoCmsIntroSection {
    __typename
    id: originalId
    heading {
      ... on DatoCmsQuote {
        ...Quote
      }
      ... on DatoCmsIntroHeading {
        __typename
        id: originalId
        heading
      }
    }
    body {
      ...BodyTextBlock
    }
  }
`
